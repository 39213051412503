document.addEventListener('DOMContentLoaded', () => {

  /* PHONE VALIDATION */
  function phoneValidation (event) {
    let string = event.target.value;
    let value = string.slice(2).replace(/\D/g, '');
    event.target.value = '';
    let arr = value.split('');
    let rdyArr = ['+7'];
    let result = '';
    arr.forEach(function(digit, index) {
        if (index == 0) {
            rdyArr.push(' (');
        }
        if (index == 3) {
            rdyArr.push(') ');
        }
        if (index == 6 || index == 8) {
            rdyArr.push(' ');
        }
        if (index > 9) {
            return;
        }
        rdyArr.push(digit);
    })
    rdyArr.forEach(function (str) {
        result += str;
    })
    event.target.value = result;
  }

  const phoneInputs = document.querySelectorAll('input[type="tel"]');
  if(phoneInputs) {
    phoneInputs.forEach(phone => {
      phone.addEventListener('input', phoneValidation);
      phone.addEventListener('focus', phoneValidation);
      phone.addEventListener('change', phoneValidation);
    })
  }

})

$(function() {

  $('.nav-button').on('click', e => {
    $('.nav').toggleClass('active');
    $('.header').toggleClass('active');
    $('body').toggleClass('no-scroll');
  })

  $('body').on('keydown', function(e) {
    if(e.which == 27) {
      $('.modal').fadeOut(300);
      $('.modal-overlay').fadeOut(300);
    }
  })

  $('.offer').on('click', function(e) {
    e.preventDefault();
    $('.modal-demo').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  })

  $('.modal-close').on('click', function(e) {
    $(this).parents('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $('.modal-overlay').on('click', function(e) {
    $('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $(document).on('wheel', function(e) {
    $('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $('input[type="tel"]').on('input focus change', function(e) {
    $(e.target).parent().removeClass('is-invalid');
  })
  
  $('.js-components').on('click', function() {
    $('.components-list .is-active').removeClass('is-active');
    $(this).parent().addClass('is-active');
  })
  $('.close-cross').on('click', function() {
    $(this).parents('.components-list .is-active').removeClass('is-active');
  })

  $('.tab-button').first().addClass('is-active');
  $('.capabilities-item').first().addClass('is-active');

  $('.nav-dropdown-icon').on('click', function() {
    if($(window).width() < 1400) {
      $(this).parents('.nav-item').find('.nav-dropdown-list').slideToggle(300);
    }
  })
})
