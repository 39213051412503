$(() => {
  
  $('.news-carousel').slick({
    arrows: true,
    appendArrows: $('.news-arrows'),
    variableWidth: false,
    swipeToSlide: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

})